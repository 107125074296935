import { cloneDeep, isEqual } from 'lodash'
export const WIZARD_VIEW_TYPES = {
	PROJECT_FORM: 'Project/Form',
	FEED: 'Activity',
	MOBILE_UNITS: 'Mobile units',
}

export const PROJECT_FORM_VIEW_TYPE = 'RECORD'
export const MOBILE_UNIT_VIEW_TYPE = 'DEVICE'
export const FEED_VIEW_TYPE = 'FEED'
export const DEFAULT_PRIMARY_FILTER_FEED = [
	{
		name: '@FeedReceiveTime',
		title: 'Date',
		type: 'DATETIME',
		format: {
			date: 'MM/DD/YYYY h:mm:ss A',
		},
	},
	{
		name: '@FeedDeviceId',
		title: 'Mobile number',
		type: 'STRING',
		format: {},
	},
]

export const DATA_OBJ_ACTION_TYPES = {
	INIT_DATA: 'initdata',
	ADD: 'add',
	UPDATE: 'update',
	DELETE: 'delete',
}

export const SETTING_TYPES = {
	PRIMARY_FILTER: 'primaryFilter',
	ADDITIONAL_FILTER: 'additionalFilter',
	USER_SPECIFIC_FILTER: 'userSpecificFilter',
	SORT_DATA: 'sortData',
	GROUP_THE_DATA: 'groupTheData',
	CALCULATIONS: 'calculations',
	COLOR_CONDITIONS: 'colorConditions',
	CHART: 'chart',
	SAVE_VIEW: 'saveView',
}

export function shouldPreventRender (prevProps, nextProps) {
	return isEqual(prevProps, nextProps)
}

export function dataObjectReducer (state, action) {
	switch (action.type) {
		case DATA_OBJ_ACTION_TYPES.INIT_DATA:
			return action.initData
		case DATA_OBJ_ACTION_TYPES.ADD: {
			const currentState = cloneDeep(state)
			currentState.push(action.target.defaultItem)
			return currentState
		}
		case DATA_OBJ_ACTION_TYPES.UPDATE: {
			const currentState = cloneDeep(state)
			// Replace item at index using native splice
			currentState.splice(action.target.index, 1, action.target.value)
			return currentState
		}
		case DATA_OBJ_ACTION_TYPES.DELETE: {
			const currentState = cloneDeep(state)
			currentState.splice(action.target.index, 1)
			return currentState
		}
		default:
			return { ...state }
	}
}

export function settingDataReducer (state, action) {
	switch (action.type) {
		case SETTING_TYPES.PRIMARY_FILTER:
			return {
				...state,
				[action.type]: action.value,
			}
		case SETTING_TYPES.ADDITIONAL_FILTER:
			return {
				...state,
				[action.type]: action.value,
			}
		case SETTING_TYPES.SORT_DATA:
			return {
				...state,
				[action.type]: action.value,
			}
		case SETTING_TYPES.GROUP_THE_DATA:
			return {
				...state,
				[action.type]: action.value,
			}
		case SETTING_TYPES.CALCULATIONS:
			return {
				...state,
				[action.type]: action.value,
			}
		case SETTING_TYPES.CHART:
			return {
				...state,
				[action.type]: action.value,
			}
		case SETTING_TYPES.COLOR_CONDITIONS:
			return {
				...state,
				[action.type]: action.value,
			}
		case SETTING_TYPES.USER_SPECIFIC_FILTER:
			return {
				...state,
				[action.type]: action.value,
			}
		default:
			return { ...state }
	}
}
