import { useContext, useState, useEffect, useMemo, memo, useLayoutEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, cloneDeep, isEqual } from 'lodash'
import { makeStyles } from '@mui/styles'
import { Delete } from '@mui/icons-material'
import {
	InputLabel,
	FormControl,
	Button,
	Autocomplete,
	Chip,
	TextField,
	Input,
	Stack,
	Typography,
	Box,
	Grid,
	Select,
	MenuItem,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import sectionsApi from 'apis/disApi/sectionsApi'

import { operatorOptions } from 'utils/functions/conditionsOptions'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import { generateRandomID } from 'components/pages/dis/helpers'
import CollapseComponent from './CollapseComponent'
import {
	SETTING_TYPES,
	DATA_OBJ_ACTION_TYPES,
	shouldPreventRender,
	dataObjectReducer,
} from '../ViewDialogUtils'

const operatorMap = {
	EQ: '=',
	NE: '!=',
}

const stringFilter = {
	contains: 'contains',
	equals: 'equals',
	startsWith: 'starts with',
	endsWith: 'ends width',
	isEmpty: 'is empty',
	isNotEmpty: 'is not empty',
	isAnyOf: 'is any of',
}
const numberFilter = {
	'=': '=',
	'!=': '!=',
	'>': '>',
	'>=': '>=',
	'<': '<',
	'<=': '<=',
	isEmpty: 'isEmpty',
	isNotEmpty: 'is not empty',
	isAnyOf: 'is any of',
}
const dateFilter = {
	is: 'is',
	not: 'not',
	after: 'after',
	onOrAfter: 'on or after',
	before: 'before',
	onOrBefore: 'on or before',
	isEmpty: 'is empty',
	isNotEmpty: 'is not empty',
}

const useStyles = makeStyles(() => ({
	closeIconSort: {
		marginRight: '20px',
		marginTop: '12px',
		width: '5%',
	},
	filterRowConType: {
		width: '10%',
	},
	filterRowField: {
		width: '30%',
	},
	filterRowOpe: {
		width: '20%',
	},
	dateBox: {
		marginTop: '16px',
	},
	input: {
		'& .MuiInput-input': {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: '400',
			fontSize: '1rem',
			lineHeight: '1.4375em',
			letterSpacing: '0.00938em',
		},
	},
}))

const getDefaultOperator = (type) => {
	if (type === 'STRING') {
		return Object.keys(stringFilter)[0]
	} else if (type === 'FLOAT' || type === 'INTEGER' || type === 'NUMBER') {
		return Object.keys(numberFilter)[0]
	} else if (type === 'DATETIME' || type === 'DATE' || type === 'TIME') {
		return Object.keys(dateFilter)[0]
	}
	return ''
}

const RenderOperatorCom = (props) => {
	const { type } = props

	return <></>
}

const RenderValueCom = (props) => {
	/* type STRING ,operator isAnyOf => textbox multi */

	/* type STRING, operator isEmpty, isNotEmpty => not show values box*/

	/* type DATETIME, operator isEmpty, isNotEmpty => not show values box */

	/* type DATETIME, orther operator => show picker */

	/* type FLOAT, INTEGER, operator isEmpty, isNotEmpty => not show values box */

	/* type FLOAT, INTEGER, other operator => show values number box */

	/* else show textbox */

	//<Input id="component-simple" InputLabelProps={{ shrink: false }} />
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const { item, changeAddFilValue } = props
	const isAnyOfOprator = useMemo(() => item.operator === 'isAnyOf', [item])

	const changeValue = (e, valuesParam) => {
		//incase isAnyOf values is an array
		let newValue = e.target.value
		if (isAnyOfOprator && valuesParam) {
			changeAddFilValue([...valuesParam])
		} else {
			if (newValue === item.value) {
				return
			}

			changeAddFilValue(newValue)
		}
	}

	return (
		<FormControl
			variant="standard"
			fullWidth
			sx={{
				'& .MuiInput-input': {
					marginTop: '0.18rem',
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					fontWeight: '400',
					lineHeight: '1.4375em',
					letterSpacing: '0.00938em',
				},
			}}
		>
			<InputLabel variant="standard" htmlFor="uncontrolled-native" shrink>
				Value
			</InputLabel>
			{item.operator === 'isEmpty' || item.operator === 'isNotEmpty' ? (
				<>{/*not show value box*/}</>
			) : (
				<>
					{item.operator === 'isAnyOf' ? (
						<>
							<Autocomplete
								multiple
								options={[]}
								freeSolo
								defaultValue={
									!isAnyOfOprator
										? item.value
										: Array.isArray(item.value)
										? [...item.value]
										: [item.value]
								}
								renderTags={(values, getTagProps) => (
									<Box sx={{ maxHeight: '30px', overflowY: 'auto' }}>
										{values.map((value, index) => (
											<Chip
												key={index}
												variant="standard"
												label={value}
												{...getTagProps({ index })}
											/>
										))}
									</Box>
								)}
								onChange={changeValue}
								sx={{
									'& .MuiAutocomplete-inputRoot': {
										height: '3em',
										paddingTop: '1em',
									},
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										type={item.type === 'FLOAT' || item.type === 'INTEGER' ? 'number' : ''}
									/>
								)}
							/>
						</>
					) : (
						<>
							{item.type === 'DATETIME' ? (
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										//label="With Time Clock
										value={item.value}
										onChange={(newValue) => {
											const newDate = new Date(newValue).toISOString()
											changeAddFilValue(newDate)
										}}
										viewRenderers={{
											hours: null,
											minutes: null,
											seconds: null,
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												InputLabelProps={{ shrink: true }}
												//onBlur={(e) => handleDateChange(value, e.target.value, true)}
												className={classes.dateBox}
												variant="standard"
											></TextField>
										)}
									/>
								</LocalizationProvider>
							) : item?.type === 'FLOAT' || item?.type === 'INTEGER' ? (
								<Input type="number" value={item.value} onChange={changeValue} />
							) : (
								<Input id="filter-value" value={item.value} onChange={changeValue} />
							)}
						</>
					)}
				</>
			)}
		</FormControl>
	)
}

const DataGroupSpecificFiltersCom = (props) => {
	const {
		item,
		index,
		userSpecificFilterDispatch,
		listActiveFieldsOptions,
		allColumns,
		listDataGroups,
	} = props
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const [field, setField] = useState(item.field)
	const [operator, setOperator] = useState(item.operator)
	const [dataGroup, setDataGroup] = useState(item.dataGroupKey)
	const [type, setType] = useState(item.type)
	const [value, setValue] = useState(!item?.value?.length ? item?.value : '')
	const [values, setValues] = useState(item?.value?.length > 0 ? [...item?.value] : [])

	// if (field && !type && viewDataObj?.allColumns?.length > 0) {
	//     setType(viewDataObj?.allColumns.find(column => column.name == field)?.type)
	// }
	useEffect(() => {
		if (item.operator === 'isAnyOf' && item?.value?.length) {
			setValues([...item.value])
		} else {
			setValue(item.value)
		}
	}, [item])

	function deleteAddFilter(id) {
		userSpecificFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.DELETE,
			target: {
				index,
			},
		})
	}

	const filterFieldChange = (e) => {
		const newField = e.target.value
		const newItem = cloneDeep(item)
		const tmpColumn = allColumns?.find((col) => col.name === newField)

		newItem['field'] = newField
		newItem['type'] = tmpColumn.type
		newItem['operator'] = getDefaultOperator(tmpColumn.type)
		newItem['value'] = ''
		userSpecificFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.UPDATE,
			target: {
				index,
				value: newItem,
			},
		})
	}

	const dataGroupChange = (e) => {
		const newDataGroup = e.target.value
		const newItem = cloneDeep(item)
		newItem['dataGroupKey'] = newDataGroup
		userSpecificFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.UPDATE,
			target: {
				index,
				value: newItem,
			},
		})
	}

	const operatorChange = (e) => {
		const newOperator = e.target.value
		const newItem = cloneDeep(item)
		newItem['operator'] = newOperator
		if (newOperator === 'isAnyOf') {
			newItem['value'] = []
		}
		userSpecificFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.UPDATE,
			target: {
				index,
				value: newItem,
			},
		})
	}

	const changeValue = (newValue) => {
		if (isEqual(item.value, newValue)) return

		const newItem = cloneDeep(item)
		newItem['value'] =
			operator !== 'isAnyOf' ? newValue : Array.isArray(newValue) ? [...newValue] : [newValue]
		userSpecificFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.UPDATE,
			target: {
				index,
				value: newItem,
			},
		})
	}

	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={1}
			style={{ justifyContent: 'center', marginBottom: '10px' }}
		>
			<Grid container fullWidth spacing={1}>
				<Grid item xs={5} md={5} lg={5}>
					<FormControl variant="standard" style={{ width: '100%' }}>
						<InputLabel id={`fieldLabel-${index}`} htmlFor="uncontrolled-native">
							Field
						</InputLabel>
						<Select
							labelId={`fieldLabel-${index}`}
							id={`fieldSelect-${index}`}
							value={item.field}
							defaultValue={item.field}
							onChange={filterFieldChange}
						>
							{listActiveFieldsOptions.map((item) => (
								<MenuItem value={item.name}>{item.title}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs md lg>
					<FormControl variant="standard" fullWidth>
						<InputLabel id={`operationsLabel-${index}`} htmlFor="uncontrolled-native">
							Operations
						</InputLabel>
						<Select
							labelId={`operationsLabel-${index}`}
							id={`operationsSelect-${index}`}
							value={item.operator}
							onChange={operatorChange}
						>
							{item.type === 'STRING' &&
								Object.keys(stringFilter).map((item2) => {
									return <MenuItem value={item2}>{stringFilter[item2]}</MenuItem>
								})}
							{(item.type === 'FLOAT' || type === 'INTEGER' || type === 'NUMBER') &&
								Object.keys(numberFilter).map((item2) => {
									return <MenuItem value={item2}>{numberFilter[item2]}</MenuItem>
								})}
							{(item.type === 'DATETIME' || type === 'DATE' || type === 'TIME') &&
								Object.keys(dateFilter).map((item2) => {
									return <MenuItem value={item2}>{dateFilter[item2]}</MenuItem>
								})}
						</Select>
					</FormControl>
				</Grid>
				{!['isNotEmpty', 'isEmpty'].includes(item.operator) && (
					<Grid item xs={2} md={2} lg={2}>
						<RenderValueCom item={item} changeAddFilValue={changeValue} />
					</Grid>
				)}
				<Grid item xs={2} md={2} lg={2}>
					<FormControl variant="standard" fullWidth>
						<InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={true}>
							Data group
						</InputLabel>
						<Select value={item.dataGroupKey} onChange={dataGroupChange}>
							{listDataGroups.map((item) => (
								<MenuItem key={item.key} value={item.key}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>

			<div>
				<Button
					aria-label="delete"
					size="small"
					variant="contained"
					sx={{
						borderRadius: '50%',
						width: '25px !important',
						minWidth: '25px !important',
						height: '25px !important',
					}}
					onClick={(e) => deleteAddFilter(item.ranID)}
				>
					<Delete fontSize="small" />
				</Button>
			</div>
		</Stack>
	)
}

const DataGroupSpecificFiltersComponent = ({
	isAddNew,
	allColumns,
	allColumnsLoading,
	clientFilter,
	listActiveFields,
	listActiveFieldsOptions,
	saveSettingDispatch,
	expandedAccordion,
	setExpandedAccordion,
	environment,
}) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const operators = useMemo(() => {
		return operatorOptions(t)
	}, [t])

	const [listDataGroups, setListDataGroups] = useState([])

	// additional filter collapse
	const [userSpecificFilter, userSpecificFilterDispatch] = useReducer(dataObjectReducer, [])

	const { data, isLoading, isFetching } = useQuery({
		queryKey: ['section'],
		queryFn: () => sectionsApi.getSections(environment.apiToken),
		enabled: isAddNew ? environment.showAddView : environment.showEditView,
	})

	useEffect(() => {
		if (isLoading || isFetching) {
			setListDataGroups([])
			return
		}

		setListDataGroups(data?.data)
	}, [data, isLoading, isFetching])

	function isIterable(obj) {
		// checks for null and undefined
		if (obj == null) {
			return false
		}
		return typeof obj[Symbol.iterator] === 'function'
	}

	useEffect(() => {
		if (isEmpty(allColumns)) return
		const filters = isEmpty(clientFilter)
			? []
			: isIterable(clientFilter)
			? [...clientFilter]
			: [clientFilter]

		if (isEmpty(filters)) {
			userSpecificFilterDispatch({
				type: DATA_OBJ_ACTION_TYPES.INIT_DATA,
				initData: [],
			})
			return
		}

		// condition get from grid not have type => first time load set type for it
		if (!filters[0]?.type) {
			const tmpAddFis = filters
				?.filter((item) => item.name)
				.map((item) => ({
					...item,
					ranID: generateRandomID(),
					type: allColumns?.find((column) => column.name == item.field)?.type,
				}))

			userSpecificFilterDispatch({
				type: DATA_OBJ_ACTION_TYPES.INIT_DATA,
				initData: tmpAddFis,
			})
			return
		}

		userSpecificFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.INIT_DATA,
			initData: filters,
		})
	}, [allColumns, clientFilter])

	useLayoutEffect(() => {
		saveSettingDispatch({
			type: SETTING_TYPES.USER_SPECIFIC_FILTER,
			value: userSpecificFilter,
		})
	}, [userSpecificFilter])

	//const title = useMemo(() => t('common:view.applyAdditionalFilters'), [t])
	const title = useMemo(() => t('common:view.userSpecificFilters'), [t])

	const addFilter = (e) => {
		//const additionalFilter = viewDataObj?.additionalFilter ? [...viewDataObj.additionalFilter] : []
		const field = listActiveFields?.length > 0 ? listActiveFields[0] : ''
		const dataGroupKey = listDataGroups?.length > 0 ? listDataGroups[0]?.key : ''
		const column = allColumns?.find((col) => col.name === field)
		const type = column?.type ? column.type : ''
		const ope = getDefaultOperator(type)
		const newItem = {
			field: field,
			type: type,
			operator: ope,
			value: '',
			dataGroupKey: dataGroupKey,
			ranID: generateRandomID(),
		}

		userSpecificFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.ADD,
			target: {
				defaultItem: newItem,
			},
		})
	}

	return (
		<CollapseComponent
			title={title}
			expanded={expandedAccordion === title}
			setExpandedAccordion={setExpandedAccordion}
			value={''}
			content={
				<div>
					{!isEmpty(listActiveFieldsOptions) &&
						userSpecificFilter?.map((item, index) => {
							return (
								<DataGroupSpecificFiltersCom
									key={item.id || item.ranID}
									index={index}
									item={item}
									userSpecificFilterDispatch={userSpecificFilterDispatch}
									listActiveFieldsOptions={listActiveFieldsOptions}
									allColumns={allColumns}
									listDataGroups={listDataGroups}
								/>
							)
						})}

					<div>
						<Button variant="text" onClick={addFilter}>
							+ Add filter
						</Button>
					</div>
				</div>
			}
		/>
	)
}

export const DataGroupSpecificFiltersCollapse = memo(
	DataGroupSpecificFiltersComponent,
	shouldPreventRender
)
