import {
	useState,
	useEffect,
	useMemo,
	memo,
	useReducer,
	useContext,
	useLayoutEffect,
	useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'

import { isEqual, isEmpty, cloneDeep } from 'lodash'
import { Add } from '@mui/icons-material'
import { Stack, Grid, Button, Typography } from '@mui/material'

import CollapseComponent from './CollapseComponent'
import ColorPickerItem from '../../../../pages/dis-v2/menu-items/_components/ConditionalColorPickerDialog/ColorPickerItem'
import SelectField from 'components/pages/dis/components/SelectField'
import {
	settingReducer,
	SETTING_DISPATCH_TYPE,
	VALUE_TYPE,
	getOperatorLabel,
} from '../../../../pages/dis-v2/menu-items/_components/ConditionalColorPickerDialog/utils'
import { SETTING_TYPES, shouldPreventRender } from '../ViewDialogUtils'
import { getColorWithoutId } from 'utils/functions/helpers'

const useStyles = makeStyles(() => ({
	primaryColumn: {
		margin: 0,
		marginBottom: '12px',

		'& .MuiOutlinedInput-root ': {
			height: '39px',
		},
	},

	colRight: {
		display: 'inline-grid',
		width: '65%',
		textAlign: 'left',
		color: '#a6a6a6',
		paddingTop: '15px',
		minHeight: '30px',
		fontSize: '0.875rem',
		fontWeight: 400,
		'&:hover': {
			cursor: 'pointer',
		},
	},
}))

function ColorPickerComponent(props) {
	const {
		listActiveFieldsOptions,
		colorConditions,
		saveSettingDispatch,
		expandedAccordion,
		setExpandedAccordion,
	} = props

	const [t] = useTranslation('common')
	const title = useMemo(() => t('common:dis.colorSettings'), [t])
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	function initBgColorRowCondition(colorConditions) {
		if (isEmpty(colorConditions)) return {}

		return { ...colorConditions }
	}

	const [bgColorRowCondition, colorSettingDispatch] = useReducer(
		settingReducer,
		colorConditions,
		initBgColorRowCondition
	)
	const primaryColumnDetail = useMemo(() => {
		if (isEmpty(bgColorRowCondition?.primaryColumn)) return {}

		return listActiveFieldsOptions.find((col) => col.name === bgColorRowCondition.primaryColumn)
	}, [listActiveFieldsOptions, bgColorRowCondition?.primaryColumn])

	useLayoutEffect(() => {
		saveSettingDispatch({
			type: SETTING_TYPES.COLOR_CONDITIONS,
			value: bgColorRowCondition,
		})
	}, [bgColorRowCondition])

	const colorList = useMemo(() => {
		const colorCondition = cloneDeep(bgColorRowCondition)
		delete colorCondition['primaryColumn']

		if (isEmpty(colorCondition)) return []
		const sortedBgColorRowCondition = Object.keys(colorCondition).sort((a, b) => {
			const idA = a.split('-')[0]
			const idB = b.split('-')[0]

			// Compare with string
			if (typeof idA === 'string' && typeof idB === 'string') {
				return idA.localeCompare(idB)
			}

			// Compare with date time
			return idA - idB
		})
		return sortedBgColorRowCondition
	}, [bgColorRowCondition])

	const optionData = useMemo(() => {
		if (isEmpty(listActiveFieldsOptions)) return []
		return listActiveFieldsOptions.map((column) => ({
			value: column.name,
			label: column.title,
		}))
	}, [listActiveFieldsOptions])

	const buildValueComponent = useCallback(() => {
		if (isEmpty(bgColorRowCondition) || !bgColorRowCondition.primaryColumn) return ''

		const primaryColumn = listActiveFieldsOptions.find(
			(col) => col.name === bgColorRowCondition.primaryColumn
		)
		if (!primaryColumn) return ''
		const primaryColumnName = `Column ${primaryColumn.title}`
		return (
			<Typography variant="subtitle2" className={classes.colRight}>
				{Object.keys(bgColorRowCondition).map((key) => {
					if (key === 'primaryColumn') return null
					const condition = bgColorRowCondition[key][0]
					const colorWithoutId = getColorWithoutId(key)
					let conditionValue = condition.value
					if (condition.operator === 'TODAY' || condition.operator === 'THISWEEK') {
						conditionValue = ''
					}

					if (condition.type !== VALUE_TYPE.CONSTANT) {
						const valueColumn = listActiveFieldsOptions.find((col) => col.name === conditionValue)
						conditionValue = valueColumn ? `${valueColumn?.title}` : conditionValue
					}

					return (
						<span>
							{primaryColumnName} {getOperatorLabel(condition.operator)?.toLowerCase()}{' '}
							{condition.type} {conditionValue}:
							<span
								style={{
									color: colorWithoutId,
									marginLeft: '5px',
								}}
							>
								{colorWithoutId}
							</span>
						</span>
					)
				})}
			</Typography>
		)
	}, [listActiveFieldsOptions, bgColorRowCondition])

	return (
		<CollapseComponent
			title={title}
			value={buildValueComponent()}
			expanded={expandedAccordion === title}
			setExpandedAccordion={setExpandedAccordion}
			content={
				<div>
					<div className={classes.primaryColumn}>
						<SelectField
							label={'Primary column'}
							onChange={(event) => {
								colorSettingDispatch({
									type: SETTING_DISPATCH_TYPE.CHANGE_PRIMARY_COLUMN,
									value: event,
								})
							}}
							value={bgColorRowCondition?.primaryColumn}
							options={optionData}
							groupKeyByOtherMenu={[]}
							isCollapse={true}
						/>
					</div>
					{primaryColumnDetail && (
						<Stack spacing={4}>
							{colorList?.length > 0 &&
								colorList.map((color, index) => {
									// only support one setting at this moment
									const formDataList = bgColorRowCondition[color][0]
									return (
										<ColorPickerItem
											isCollapse={true}
											key={color}
											primaryColumnDetail={primaryColumnDetail}
											columns={listActiveFieldsOptions}
											color={color}
											formDataList={formDataList}
											isShowAddMoreButton={index === colorList.length - 1}
											onSettingChange={colorSettingDispatch}
										/>
									)
								})}
							{!isEmpty(primaryColumnDetail) && colorList?.length === 0 && (
								<Grid
									container
									sx={{
										display: 'flex',
										justifyContent: 'flex-end',
										alignItems: 'center',
									}}
								>
									<Grid item>
										<Button
											aria-label="add"
											size="small"
											variant="contained"
											sx={{
												borderRadius: '50%',
												width: '25px !important',
												minWidth: '25px !important',
												height: '25px !important',
											}}
											onClick={() => {
												colorSettingDispatch({ type: SETTING_DISPATCH_TYPE.ADD_NEW_COLOR })
											}}
										>
											<Add fontSize="small" />
										</Button>
									</Grid>
								</Grid>
							)}
						</Stack>
					)}
				</div>
			}
		/>
	)
}

export const ColorPickerCollapse = memo(ColorPickerComponent, shouldPreventRender)
